import { inject } from "@angular/core";
import { AuthService, BrowserService } from "./shared/services";
import { ActivatedRouteSnapshot, CanActivateFn, Router } from "@angular/router";

export const authGuardfn: CanActivateFn = (next: ActivatedRouteSnapshot) => {
  const auth = inject(AuthService);
  const router = inject(Router);
  const browserService = inject(BrowserService);
  if (browserService.isPlatformBrowser()) {
    if (!auth.getToken()) {
      localStorage.setItem("lastUrl", window.location.href);
      router.navigate(["/inicio-sesion"]);
      return false;
    }
  }

  return true;
};
