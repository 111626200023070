import { municipalitiesResolverFn } from "./shared/resolvers/municipalities.resolver";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CarsDetailsResolverFn } from "./shared/resolvers/cars-details.resolver";
import { regionsResolverFn } from "./shared/resolvers/regions.resolver";
import { citiesResolverFn } from "./shared/resolvers/cities.resolver";
import { QuicklinkStrategy } from "ngx-quicklink";
import { authGuardfn } from "./app.guard";
import { generalroutes } from "./routes/general.routes";
import { legacyRoutes } from "./routes/legacy.routes";
import { dashboardRoutes } from "./routes/dashboard.routes";
import { onboardingRoutes } from "./routes/onboarding.routes";
import { authRoutes } from "./routes/auth.routes";

const suscriptionRoutes: Routes = [
  {
    // id de CarVersion Params: period=...&kms=...&deductible=...
    path: "suscripcion/:model/condicion/:condition",
    loadChildren: () =>
      import("./modules/listing/subscription/subscription.module").then(
        (m) => m.SubscriptionModule
      ),
    resolve: {
      carsData: CarsDetailsResolverFn,
      regions: regionsResolverFn,
      municipalities: municipalitiesResolverFn,
      cities: citiesResolverFn,
    },
  },
  {
    path: "suscripcion/adicional/:model/condicion/:condition",
    loadChildren: () =>
      import("./modules/listing/subscription/subscription.module").then(
        (m) => m.SubscriptionModule
      ),
    canActivate: [authGuardfn],
    resolve: {
      carsData: CarsDetailsResolverFn,
      regions: regionsResolverFn,
      municipalities: municipalitiesResolverFn,
      cities: citiesResolverFn,
    },
  },
];

const otherRoutes: Routes = [
  {
    path: "entrega/exitosa",
    loadComponent: () =>
      import(
        "./pages/onboarding/delivery-successful/delivery-successful.component"
      ).then((c) => c.DeliverySuccessfulComponent),
  },
  {
    // id del Client con Params: vt=...&email=...&name=...&lastName=...&emailValidation=true
    path: "cuenta/validacion/:id",
    loadComponent: () =>
      import("./pages/onboarding/validation/validation.component").then(
        (c) => c.ValidationComponent
      ),
  },
];

export const routes: Routes = [
  ...generalroutes,
  ...dashboardRoutes,
  ...onboardingRoutes,
  ...authRoutes,
  ...suscriptionRoutes,
  ...legacyRoutes,
  ...otherRoutes,
  {
    path: "**",
    title: "¡Ups! Parece que hay un problema",
    loadComponent: () =>
      import("./pages/error/error.component").then((c) => c.ErrorComponent),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: QuicklinkStrategy,
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
      initialNavigation: "enabledBlocking",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
