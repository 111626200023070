import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { Apollo, gql } from "apollo-angular";
import { map, take } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ContractEcertRequestResolver {
  constructor(private apollo: Apollo) {}
  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const id = route.paramMap.get("id");
    const GET_SUBSCRIPTION_APPLICATIONS_QUERY = gql`
      query GetSubscriptionApplication($id: ID!) {
        subscriptionApplication(id: $id) {
          id
          rawId
          status
          periods
          kilometersPerYear
          deductible
          monthValue
          ufMonthValue
          executive {
            id
            name
            lastName
            phone
          }
          eSignatureDocument {
            eSignatureUrl
            status
          }
          currentCarReserve {
            id
            rawId
            periods
            initialPayment
            kilometersPerYear
            deductible
            monthValue
            clpMonthValue
            finalMonthValue
            car {
              id
              carOfferPrices
              conditionInfo
              daysUntilDelivery
              initialMileage
              color {
                id
                name
              }
              carVersion {
                id
                rawId
                version
                year
                gasType
                transmission
                type
                info
                labeledImages
                carBase {
                  id
                  maker
                  model
                }
              }
            }
          }
          client {
            id
            rawId
            name
            lastName
            run
            email
            companyInfo
          }
          requiredDocuments {
            edges {
              node {
                id
                name
                description
              }
            }
          }
          uploadedDocuments {
            edges {
              node {
                id
                rawId
                type {
                  id
                  name
                }
                createdAt
                contentUrl
                valid
                rejectionReason {
                  id
                  name
                  description
                }
              }
            }
          }
          carVersion {
            id
            version
            year
            gasType
            transmission
            type
            info
            labeledImages
            carBase {
              id
              maker
              model
            }
          }
          lastSubscriptionApplicationTerms {
            id
            clpInitialPayment
            ufMonthValue
            monthValue
            finalUFMonthValue
            finalMonthValue
            ufInitialPayment
            clpDiscount
            ufDiscount
          }
        }
      }
    `;

    return this.apollo
      .watchQuery<any>({
        query: GET_SUBSCRIPTION_APPLICATIONS_QUERY,
        variables: {
          id: "/subscription_applications/" + id.toString(),
        },
      })
      .valueChanges.pipe(
        map(({ data }) => {
          return data.subscriptionApplication;
        }),
        map((subscriptionApplication) => {
          const { currentCarReserve, requiredDocuments, uploadedDocuments } =
            subscriptionApplication;

          const initialMileage = currentCarReserve?.car?.initialMileage || 0;

          const _requiredDocuments =
            requiredDocuments?.edges?.map(({ node }) => ({
              name: node.name,
              description: node.description,
            })) || [];

          const _uploadedDocuments =
            uploadedDocuments?.edges?.map(({ node }) => node) || [];

          return {
            ...subscriptionApplication,
            requiredDocuments: _requiredDocuments,
            uploadedDocuments: _uploadedDocuments,
            currentCarReserve: {
              ...currentCarReserve,
              car: { ...currentCarReserve.car, initialMileage },
            },
          };
        }),

        take(1)
      );
  }
}
