import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveFn } from "@angular/router";
import { CatalogueService } from "app/shared/services";
import { CarEntryV2 } from "app/shared/model/car-entry";

export const carsViewResolverFn: ResolveFn<CarEntryV2[]> = (
  activatedRouteSnapshot: ActivatedRouteSnapshot
) => {
  const catalogueService = inject(CatalogueService);
  const code = activatedRouteSnapshot.queryParamMap.get("code");
  const referrals = activatedRouteSnapshot.queryParamMap.get("referrals");
  const renewalCode = activatedRouteSnapshot.queryParamMap.get("renewalCode");
  return catalogueService.getCars({ code, referrals, renewalCode });
};
