import { Client } from "./shared/model/client";
import { SharedService } from "./shared/services/shared.service";
import { AuthService } from "./shared/services/auth.service";
import {
  Component,
  OnInit,
  Inject,
  PLATFORM_ID,
  HostListener,
} from "@angular/core";
import {
  BrowserService,
  Hotjar,
  MetafrenzyService,
  Saber,
  SubscriptionApiService,
} from "../app/shared/services";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { MatDialog } from "@angular/material/dialog";
import { SessionExpiredModalComponent } from "./modals/session-expired-modal/session-expired-modal.component";

@Component({
  selector: "app-root",
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit {
  switchUser = "";

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private hotjar: Hotjar,
    private saber: Saber,
    private router: Router,
    private browserService: BrowserService,
    private metafrenzyService: MetafrenzyService,
    private dialog: MatDialog,
    private authService: AuthService,
    private api: SubscriptionApiService,
    @Inject(GoogleTagManagerService) private gtmService: GoogleTagManagerService
  ) {}

  @HostListener("document:visibilitychange", ["event"])
  visibilityChange() {
    if (
      !document.hidden &&
      this.browserService.isPlatformBrowser() &&
      localStorage.getItem("switch_user") != null
    ) {
      const changeUser =
        localStorage.getItem("switch_user") !== this.switchUser;
      if (changeUser) {
        const sub = this.api
          .getClientV2(localStorage.getItem("switch_user"))
          .subscribe({
            next: (client) => {
              sub.unsubscribe();
              this.openModal(client);
            },
          });
      }
    }
  }

  canonicalTag(): void {
    if (this.browserService.isPlatformBrowser()) {
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe(() => {
          this.metafrenzyService.removeCanonical();
          this.metafrenzyService.setCanonical(window.location.href);
        });
    }
  }

  ngOnInit(): void {
    this.hotjar.init();
    this.saber.init();
    this.canonicalTag();
    if (this.browserService.isPlatformBrowser()) {
      this.router.events.forEach((item) => {
        if (item instanceof NavigationEnd) {
          const gtmTag = {
            event: "page",
            pageName: item.url,
          };
          this.gtmService.pushTag(gtmTag);
        }
      });
      if (this.authService.isLogged()) {
        this.switchUser = localStorage.getItem("switch_user");
      }
    }
  }

  openModal(client: Client): void {
    this.dialog.open(SessionExpiredModalComponent, {
      width: "600px",
      data: { ...client },
    });
  }
}
