import { inject } from "@angular/core";
import { ResolveFn } from "@angular/router";
import {
  ChileanCitiesStructure,
  ChileanCitiesService,
} from "app/shared/services";
import { Observable } from "rxjs";

export const citiesResolverFn: ResolveFn<ChileanCitiesStructure[]> = () => {
  const chileanCitiesService = inject(ChileanCitiesService);
  return chileanCitiesService.getCitiesV2() as Observable<
    ChileanCitiesStructure[]
  >;
};
