import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "environments/environment";
import { AuthService, BrowserService } from "app/shared/services";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthService,
    private browserService: BrowserService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.browserService.isPlatformBrowser()) {
      if (
        (request.url.startsWith(environment.apiUrl) ||
          request.url.startsWith(environment.graphcdnUrl)) &&
        this.auth.getToken()
      ) {
        if (!this.auth.hasExpired()) {
          request = request.clone({
            setParams:
              localStorage.getItem("switch_user") !== "undefined" &&
              localStorage.getItem("switch_user") !== undefined &&
              localStorage.getItem("switch_user") !== null
                ? { _switch_user: localStorage.getItem("switch_user") }
                : {},
            setHeaders: {
              Authorization: `${this.auth.getTokenType()} ${this.auth.getToken()}`,
            },
          });
        } else {
          this.auth.logout();
        }
      }
    }

    return next.handle(request);
  }
}
