import { Title } from "@angular/platform-browser";
import { Routes } from "@angular/router";
import { authGuardfn } from "app/app.guard";

export const legacyRoutes: Routes = [
  {
    // con Params: vt=...&email=...&name=...&lastName=...
    path: "legacy/cuenta",
    title: "Crear Cuenta",
    loadComponent: () =>
      import("../pages/legacy/create-password/create-password.component").then(
        (c) => c.CreatePasswordComponent
      ),
  },
  {
    // id de SubscriptionContract
    path: "legacy/renovacion/:id",
    title: "Pago",
    loadComponent: () =>
      import("../pages/legacy/legacy-payment/legacy-payment.component").then(
        (c) => c.LegacyPaymentComponent
      ),
    canActivate: [authGuardfn],
  },
  {
    // id de Contract con Params: cart_id=...&kushkiToken=...&kushkiPaymentMethod=...&name=...&lastName=...&email=...
    path: "legacy/pago/validacion/:id",
    title: "Validación de pago",
    loadComponent: () =>
      import(
        "../pages/legacy/payment-validation/payment-validation.component"
      ).then((c) => c.PaymentValidationComponent),
    canActivate: [authGuardfn],
  },
];
