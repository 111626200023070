import { Routes } from "@angular/router";
import { authGuardfn } from "app/app.guard";
import { citiesResolverFn } from "app/shared/resolvers/cities.resolver";
import { ContractEcertRequestResolver } from "app/shared/resolvers/contract-ecert-request.resolver";
import { municipalitiesResolverFn } from "app/shared/resolvers/municipalities.resolver";
import { regionsResolverFn } from "app/shared/resolvers/regions.resolver";

export const onboardingRoutes: Routes = [
  {
    // id de Solicitud con Params: vt=...&email=...&name=...&lastName=...
    path: "onboarding/cuenta/:id",
    title: "Crear contraseña",
    loadComponent: () =>
      import("../pages/auth/create-password/create-password.component").then(
        (c) => c.CreatePasswordComponent
      ),
    resolve: {
      regions: regionsResolverFn,
      municipalities: municipalitiesResolverFn,
      cities: citiesResolverFn,
    },
  },
  {
    path: "onboarding/instrucciones-beta",
    title: "Bienvenido a Smartycar",
    loadComponent: () =>
      import(
        "../pages/onboarding/manual-evaluation-instructions/manual-evaluation-instructions.component"
      ).then((c) => c.ManualEvaluationInstructionsComponent),
  },

  {
    path: "onboarding/selector",
    loadComponent: () =>
      import("../pages/onboarding/selector/selector.component").then(
        (c) => c.SelectorComponent
      ),
    canActivate: [authGuardfn],
  },
  {
    // id de Solicitud
    path: "onboarding/contrato/tradicional/:id",
    loadComponent: () =>
      import(
        "../pages/onboarding/tradicional-signature/traditional-signature.component"
      ).then((c) => c.TraditionalSignatureComponent),
    canActivate: [authGuardfn],
  },
  {
    // id de Solicitud
    path: "onboarding/aprobacion/:id",
    // tslint:disable-next-line:max-line-length
    loadComponent: () =>
      import(
        "../pages/onboarding/subscription-approval/subscription-approval.component"
      ).then((c) => c.SubscriptionApprovalComponent),
    canActivate: [authGuardfn],
  },
  {
    // id de Solicitud con Params: cart_id=...&kushkiToken=...&kushkiPaymentMethod=...&name=...&lastName=...&email=...
    path: "onboarding/pago/validacion/:id",
    loadComponent: () =>
      import(
        "../pages/onboarding/payment-validation/payment-validation.component"
      ).then((c) => c.PaymentValidationComponent),
    canActivate: [authGuardfn],
  },
  {
    // id de Solicitud
    path: "onboarding/pago/:id",
    title: "Pago",
    loadComponent: () =>
      import("../pages/onboarding/payment/payment.component").then(
        (c) => c.PaymentComponent
      ),
    canActivate: [authGuardfn],
  },
  {
    // id de Solicitud
    path: "onboarding/validacion/identidad/:id",
    loadComponent: () =>
      import(
        "../pages/onboarding/identity-validation/identity-validation.component"
      ).then((c) => c.IdentityValidationComponent),
    canActivate: [authGuardfn],
  },
  {
    // id de Solicitud
    path: "onboarding/contrato/:id",
    loadComponent: () =>
      import(
        "../pages/onboarding/contract-ecert-request/contract-ecert-request.component"
      ).then((c) => c.ContractEcertRequestComponent),
    resolve: {
      contractEcertRequestResolver: ContractEcertRequestResolver,
    },
    canActivate: [authGuardfn],
  },
  {
    // id de Solicitud
    path: "onboarding/contrato/legacy/:id",
    loadComponent: () =>
      import(
        "../pages/onboarding/contract-request/contract-request.component"
      ).then((c) => c.ContractRequestComponent),
    canActivate: [authGuardfn],
  },
  {
    // id de Entrega con params: event=signature_request_signed&signature_id=...
    path: "onboarding/entrega/:id",
    loadComponent: () =>
      import("../pages/onboarding/delivery/delivery.component").then(
        (c) => c.DeliveryComponent
      ),
    canActivate: [authGuardfn],
  },
  {
    // id de Solicitud
    path: "onboarding/validacion/identidad/documentos/:id",
    loadComponent: () =>
      import(
        "../pages/onboarding/document-validation/document-validation.component"
      ).then((c) => c.DocumentValidationComponent),
    canActivate: [authGuardfn],
  },
  {
    // id de la SubApplication con Params: vt=...&email=...&name=...&lastName=...
    path: "onboarding/validacion/:id",
    loadComponent: () =>
      import("../pages/onboarding/validation/validation.component").then(
        (c) => c.ValidationComponent
      ),
  },
  {
    // id de Solicitud
    path: "onboarding/revision/:id",
    loadComponent: () =>
      import("../pages/onboarding/documents/documents.component").then(
        (c) => c.DocumentsComponent
      ),
    canActivate: [authGuardfn],
  },
  {
    // id de Solicitud
    path: "onboarding/documentos/:id",
    loadComponent: () =>
      import("../pages/onboarding/documents/documents.component").then(
        (c) => c.DocumentsComponent
      ),
    canActivate: [authGuardfn],
  },
  {
    path: "onboarding/documentos/:id/:success",
    loadComponent: () =>
      import("../pages/onboarding/documents/documents.component").then(
        (c) => c.DocumentsComponent
      ),
    canActivate: [authGuardfn],
  },
  {
    path: "onboarding/contactabilidad",
    loadComponent: () =>
      import(
        "../pages/onboarding/contact-verification/contact-verification.component"
      ).then((c) => c.ContactVerificationComponent),
  },
];
