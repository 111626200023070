import { SharedService } from "../services/shared.service";
import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveFn } from "@angular/router";
import { UICarVersions } from "app/shared/model/selected-car.model";
import { SubscriptionApiService } from "app/shared/services";
import { of } from "rxjs";
import { mergeMap } from "rxjs/operators";

export const CarsDetailsResolverFn: ResolveFn<UICarVersions> = (
  route: ActivatedRouteSnapshot
) => {
  //Injections
  const subscriptionApiService = inject(SubscriptionApiService);
  const sharedService = inject(SharedService);
  //Params router
  const model = route.paramMap.get("model");
  const condition = route.paramMap.get("condition");
  const code =
    route.queryParamMap.get("code") ||
    route.queryParamMap.get("discountCode") ||
    route.queryParamMap.get("renewalCode");
  const referrals = route.queryParamMap.get("referrals");
  const run = route.queryParamMap.get("discountRun");
  //const
  const isNewCar = condition === "nuevo";
  const cacheBuster = `cacheBuster=${new Date().getTime()}`;
  const carUrl = isNewCar
    ? `carVersion=${model}&${cacheBuster}`
    : `car=${model}&${cacheBuster}`;
  //Logic
  if (code) {
    const sendData = {
      code,
      carVersion: isNewCar ? `/car_versions/${model}` : null,
      car: !isNewCar ? `/cars/${model}` : null,
      run: run !== undefined ? run : undefined,
    };
    return subscriptionApiService.postCarDetailDiscount(sendData).pipe(
      mergeMap((data: any) => {
        if (data?.message) {
          return subscriptionApiService.getCarDetail(carUrl);
        } else {
          sharedService.setIsDiscountActive(true);
          return of(data); // Mantén el mismo observable si no hay mensaje
        }
      })
    );
  } else if (referrals) {
    const sendData = {
      code: referrals,
      carVersion: isNewCar ? `/car_versions/${model}` : null,
      car: !isNewCar ? `/cars/${model}` : null,
    };
    return subscriptionApiService
      .postReferralDiscountApplication(sendData)
      .pipe(
        mergeMap((data: any) => {
          if (data?.message) {
            return subscriptionApiService.getCarDetail(carUrl);
          } else {
            sharedService.setIsDiscountActive(true);
            return of(data); // Mantén el mismo observable si no hay mensaje
          }
        })
      );
  }
  return subscriptionApiService.getCarDetail(carUrl);
};
