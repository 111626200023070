import { SharedService } from "app/shared/services/shared.service";
import { Client } from "./shared/model";
import {
  TenantVariablesService,
  SubscriptionApiService,
  AuthService,
  BrowserService,
} from "./shared/services";
import { finalize, take } from "rxjs/operators";

export function initializeApp(
  tenantVariablesService: TenantVariablesService,
  subscriptionApiService: SubscriptionApiService,
  authService: AuthService,
  browserService: BrowserService,
  sharedService: SharedService
): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    const removeItemsError = (error) => {
      if (error?.error?.code === 401) {
        localStorage.removeItem("token_type");
        localStorage.removeItem("h_rs");
        localStorage.removeItem("switch_user");
      }
    };

    tenantVariablesService.startTenantVariables().subscribe({
      next: (data) => tenantVariablesService.setTenantVariables(data),
      error: (error) => removeItemsError(error),
    });

    if (browserService.isPlatformBrowser()) {
      const loadClient = (run: string) => {
        subscriptionApiService
          .getClientV2(run)
          .pipe(
            finalize(() => {
              console.log("Initialization completed");
              resolve();
            })
          )
          .subscribe({
            next: (data) =>
              sharedService.setIsActiveClient({ ...data } as Client),
            error: (error) => removeItemsError(error),
          });
      };
      if (
        authService.isLogged() &&
        sharedService.getValueActiveClient() === null &&
        !window.location.search.includes("switch_user")
      ) {
        loadClient(authService.getUsername());
      } else if (window.location.search.includes("switch_user")) {
        const queryParamsString = window.location.search.substring(1); // Obtener la cadena de consulta sin el "?"
        const queryParams = new URLSearchParams(queryParamsString);
        const queryParamsObject: { [key: string]: string } = {};
        queryParams.forEach((value, key) => {
          queryParamsObject[key] = value;
        });
        authService.registerCredential({
          token: queryParamsObject.sm_t,
          refresh_token: "",
          switchUser: queryParamsObject["switch_user"],
        });
        loadClient(queryParamsObject["switch_user"]);
      } else {
        resolve();
      }
    } else {
      resolve();
    }
  });
}
