import { BrowserService } from "./../../shared/services/browser.service";
import { CommonModule } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from "@angular/material/dialog";
import { Client } from "app/shared/model";
import { getNameUser } from "app/utils/utils";

@Component({
  standalone: true,
  selector: "app-session-expired-modal",
  template: ` <mat-dialog-content>
    <main class="dialog">
      <h3 class="dialog--title">
        <i class="fal fa-clock"></i> Sesión Expirada
      </h3>
      <p class="dialog--text">
        Acabas de iniciar sesión con el usuario
        <strong>{{ nameUser }}</strong> en otra pestaña. Esta Sesión dejara de
        hacer efecto.
      </p>
      <div class="dialog--buttons">
        <button class="button error" mat-dialog-close (click)="close()">
          Entendido
        </button>
      </div>
    </main>
  </mat-dialog-content>`,
  styleUrls: ["./session-expired-modal.component.scss"],
  imports: [CommonModule, MatDialogModule],
})
export class SessionExpiredModalComponent implements OnInit {
  nameUser = "";

  constructor(
    public dialogRef: MatDialogRef<SessionExpiredModalComponent>,
    private browserService: BrowserService,
    @Inject(MAT_DIALOG_DATA) public data: Client
  ) {}

  ngOnInit(): void {
    this.nameUser = getNameUser(this.data);
  }

  close(): void {
    if (this.browserService.isPlatformBrowser()) {
      //Espacio de mejora para eliminar todo lo que se pueda almacenar en locastorage
      window.location.href = "/onboarding/selector";
    }
    this.dialogRef.close();
  }
}
