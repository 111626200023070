import { inject } from "@angular/core";
import { ResolveFn } from "@angular/router";
import {
  ChileanCitiesStructure,
  ChileanCitiesService,
} from "app/shared/services";

export const municipalitiesResolverFn: ResolveFn<
  ChileanCitiesStructure[]
> = () => {
  const chileanCitiesService = inject(ChileanCitiesService);
  return chileanCitiesService.getMunicipalitiesV2();
};
