import { Routes } from "@angular/router";
import { authGuardfn } from "app/app.guard";
import { CarsDetailsResolverFn } from "app/shared/resolvers/cars-details.resolver";
import { carsViewResolverFn } from "app/shared/resolvers/cars-view.resolver";
import { citiesResolverFn } from "app/shared/resolvers/cities.resolver";
import { municipalitiesResolverFn } from "app/shared/resolvers/municipalities.resolver";
import { regionsResolverFn } from "app/shared/resolvers/regions.resolver";

const section = "dashboard";

export const dashboardRoutes: Routes = [
  {
    path: "dashboard",
    redirectTo: "dashboard/inicio",
    pathMatch: "full",
  },
  {
    path: `${section}/inicio`,
    title: "Smartycar - Dashboard - Home",
    loadComponent: () =>
      import("../pages/dashboard/dashboard-home/dashboard-home.component").then(
        (c) => c.DashboardHomeComponent
      ),
    canActivate: [authGuardfn],
  },
  {
    path: `${section}/cuenta`,
    title: "Configuración de cuenta",
    loadComponent: () =>
      import(
        "../pages/dashboard/dashboard-account/dashboard-account.component"
      ).then((c) => c.DashboardAccountComponent),
    canActivate: [authGuardfn],
  },
  {
    path: `${section}/cesion-de-contrato`,
    title: "Cesion de contrato",
    loadComponent: () =>
      import(
        "../pages/dashboard/dashboard-transfer-contracts/dashboard-transfer-contracts.component"
      ).then((c) => c.DashboardTransferContractsComponent),
    canActivate: [authGuardfn],
  },
  {
    path: `${section}/suscripciones`,
    title: "Suscripciones Activas",
    loadComponent: () =>
      import(
        "../pages/dashboard/dashboard-subscription/dashboard-subscription.component"
      ).then((c) => c.DashboardSubscriptionComponent),
    canActivate: [authGuardfn],
  },
  {
    path: `${section}/suscripciones/:id/:tab`,
    title: "Suscripciones Activas",
    loadComponent: () =>
      import(
        "../pages/dashboard/dashboard-subscription/dashboard-subscription.component"
      ).then((c) => c.DashboardSubscriptionComponent),
    canActivate: [authGuardfn],
  },
  {
    path: `${section}/renovacion`,
    title: "Renovación",
    loadComponent: () =>
      import(
        "../pages/dashboard/dashboard-renewal/dashboard-renewal.component"
      ).then((c) => c.DashboardRenewalComponent),
    canActivate: [authGuardfn],
    resolve: {
      carsData: carsViewResolverFn,
    },
  },
  {
    path: `${section}/smarty-adicional`,
    title: "Renueva tu Smarty",
    loadComponent: () =>
      import(
        "../pages/dashboard/dashboard-additional/dashboard-additional.component"
      ).then((c) => c.DashboardAdditionalComponent),
    canActivate: [authGuardfn],
    resolve: {
      carsData: carsViewResolverFn,
    },
  },
  {
    path: `${section}/notificaciones`,
    loadComponent: () =>
      import(
        "../pages/dashboard/dashboard-notifications/dashboard-notifications.component"
      ).then((c) => c.DashboardNotificationsComponent),
    canActivate: [authGuardfn],
  },
  {
    path: `${section}/notificaciones/:id`,
    loadComponent: () =>
      import(
        "../pages/dashboard/dashboard-notifications/dashboard-notifications.component"
      ).then((c) => c.DashboardNotificationsComponent),
    canActivate: [authGuardfn],
  },
  {
    path: `${section}/bienvenida`,
    title: "Bienvenida - Inició de sesión",
    loadComponent: () =>
      import(
        "../pages/dashboard/dashboard-welcome/dashboard-welcome.component"
      ).then((c) => c.DashboardWelcomeComponent),
  },
  {
    path: `${section}/soporte`,
    title: "Smartycar - Soporte",
    loadComponent: () =>
      import(
        "../pages/dashboard/dashboard-ticket-center/dashboard-ticket-center.component"
      ).then((c) => c.DashboardTicketCenterComponent),
  },
  {
    path: `${section}/referidos/:id`,
    title: "Smartycar - Referidos",
    loadComponent: () =>
      import(
        "../pages/dashboard/dashboard-referrals-details/dashboard-referrals-details.component"
      ).then((c) => c.DashboardReferralsDetailsComponent),
  },
  {
    path: `${section}/siniestros`,
    title: "Smartycar - Siniestros",
    loadComponent: () =>
      import(
        "../pages/dashboard/dashboard-sinister/dashboard-sinister.component"
      ).then((c) => c.DashboardSinisterComponent),
  },
  {
    path: `${section}/renovacion/detalle/modelo/:model/condicion/:condition`,
    loadComponent: () =>
      import(
        "../pages/dashboard/dashboard-renewal-details/dashboard-renewal-details.component"
      ).then((c) => c.DashboardRenewalDetailsComponent),
    canActivate: [authGuardfn],
    resolve: {
      carsData: CarsDetailsResolverFn,
    },
  },
  {
    path: `${section}/siniestros/:id`,
    loadComponent: () =>
      import(
        "../pages/dashboard/dashboard-sinister-inscription/dashboard-sinister-inscription.component"
      ).then((c) => c.DashboardSinisterInscriptionComponent),
  },
  {
    path: `${section}/suscripcion/:model/condicion/:condition`,
    loadChildren: () =>
      import("../modules/listing/subscription/subscription.module").then(
        (m) => m.SubscriptionModule
      ),
    canActivate: [authGuardfn],
    resolve: {
      carsData: CarsDetailsResolverFn,
      regions: regionsResolverFn,
      municipalities: municipalitiesResolverFn,
      cities: citiesResolverFn,
    },
  },
  {
    path: `${section}/referidos`,
    loadComponent: () =>
      import(
        "../pages/dashboard/dashboard-referrals/dashboard-referrals.component"
      ).then((c) => c.DashboardReferralsComponent),
  },
];
