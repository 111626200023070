import { SharedService } from "./shared/services/shared.service";
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  NgModule,
  APP_INITIALIZER,
  Injectable,
  ErrorHandler,
} from "@angular/core";
import {
  HTTP_INTERCEPTORS,
  HttpClientJsonpModule,
  HttpClientModule,
} from "@angular/common/http";
import {
  BrowserModule,
  provideClientHydration,
} from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CommonModule } from "@angular/common";
import * as Sentry from "@sentry/browser";
import { environment } from "../environments/environment";
import { SubscriptionApplicationStatesUtil } from "./shared/services/subscription-application-states-util.service";
import { TokenInterceptor } from "./shared/interceptors/token.interceptor";
import {
  AirtableService,
  AuthService,
  BrowserService,
  ChileanCitiesService,
  LocalStorageService,
  MetafrenzyService,
  Hotjar,
  UfService,
  UtmSourceService,
  Saber,
  TenantVariablesService,
  TransferStateService,
  SubscriptionApiService,
} from "./shared/services";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { MatDialogRef } from "@angular/material/dialog";
import { initializeApp } from "./app-initializer";
import { MatButtonModule } from "@angular/material/button";
import { MatSliderModule } from "@angular/material/slider";
import { QuicklinkModule } from "ngx-quicklink";
import { APOLLO_OPTIONS, ApolloModule } from "apollo-angular";
import { HttpLink } from "apollo-angular/http";
import { InMemoryCache } from "@apollo/client/core";
import { SessionExpiredModalComponent } from "./modals/session-expired-modal/session-expired-modal.component";
import { PipesModule } from "./shared/pipes";

Sentry.init({
  dsn: "https://a113d7f6325d4839b6c8b8ac771df05d@o151155.ingest.sentry.io/5728048",
  environment: environment.sentryEnvironment,
  release: environment.sentryRelease,
});

export function initializeAppFactory(
  tenantVariablesService: TenantVariablesService,
  subscriptionApiService: SubscriptionApiService,
  authService: AuthService,
  browserService: BrowserService,
  sharedService: SharedService
): () => Promise<void> {
  return () =>
    initializeApp(
      tenantVariablesService,
      subscriptionApiService,
      authService,
      browserService,
      sharedService
    );
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  handleError(error: any): void {
    if (!environment.production) {
      console.error(error);
    }

    Sentry.captureException(error.originalError || error);
    // Sentry.showReportDialog({ eventId });
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    SessionExpiredModalComponent,
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    ApolloModule,
    HttpClientJsonpModule,
    AppRoutingModule,
    MatButtonModule,
    MatSliderModule,
    QuicklinkModule,
    PipesModule,
  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {},
    },
    AirtableService,
    AuthService,
    BrowserService,
    ChileanCitiesService,
    Hotjar,
    LocalStorageService,
    MetafrenzyService,
    Saber,
    TenantVariablesService,
    TransferStateService,
    UfService,
    UtmSourceService,
    SubscriptionApplicationStatesUtil,
    { provide: "googleTagManagerId", useValue: environment.tagManagerKey },
    {
      provide: APOLLO_OPTIONS,
      useFactory(httpLink: HttpLink) {
        return {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: environment.graphcdnUrl,
          }),
        };
      },
      deps: [HttpLink],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [
        TenantVariablesService,
        SubscriptionApiService,
        AuthService,
        BrowserService,
        SharedService,
      ],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    provideClientHydration(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
