import { Routes } from "@angular/router";

export const authRoutes: Routes = [
  {
    path: "contraseña/cambio",
    title: "Cambiar contraseña",
    loadComponent: () =>
      import("../pages/auth/change-password/change-password.component").then(
        (c) => c.ChangePasswordComponent
      ),
  },
  {
    // Params: created=...
    path: "inicio-sesion",
    title: "Inicia sesión en Smartycar",
    loadComponent: () =>
      import("../pages/auth/login/login.component").then(
        (c) => c.LoginComponent
      ),
  },
  {
    // Con Params: vt=...&run?=...
    path: "contraseña/recuperacion",
    title: "Recupera tu contraseña",
    loadComponent: () =>
      import("../pages/auth/recover-password/recover-password.component").then(
        (c) => c.RecoverPasswordComponent
      ),
  },
  {
    // Con Params: email?=...
    path: "contraseña/perdida",
    title: "Olvide mi Contraseña",
    loadComponent: () =>
      import("../pages/auth/forgot-password/forgot-password.component").then(
        (c) => c.ForgotPasswordComponent
      ),
  },
];
