import { Routes } from "@angular/router";
import { CarsDetailsResolverFn } from "app/shared/resolvers/cars-details.resolver";
import { carsViewResolverFn } from "app/shared/resolvers/cars-view.resolver";

export const generalroutes: Routes = [
  {
    path: "",
    title: "Smartycar · La manera inteligente de tener un auto",
    loadComponent: () =>
      import("../pages/catalogue/cars-view/cars-view.component").then(
        (c) => c.CarsViewComponent
      ),
    resolve: {
      carsData: carsViewResolverFn,
    },
  },
  {
    path: "autos",
    title: "Smartycar · La manera inteligente de tener un auto",
    loadComponent: () =>
      import("../pages/catalogue/cars-view/cars-view.component").then(
        (c) => c.CarsViewComponent
      ),
    resolve: {
      carsData: carsViewResolverFn,
    },
  },
  {
    path: "autos/detalle/modelo/:model/condicion/:condition",
    title: "Detalle del vehiculo",
    loadComponent: () =>
      import("../pages/catalogue/cars-details/cars-details.component").then(
        (m) => m.CarsDetailsComponent
      ),
    resolve: {
      carsData: CarsDetailsResolverFn,
    },
  },
];
